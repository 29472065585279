import React from "react";
import { PageProps } from "gatsby";
import { Layout } from "../layouts/layout";
import { Container } from "../layouts/container";
import { SEO } from "../seo";
import { Button, Grid, Typography } from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";
import { GatsbyShop } from "../utils/shop";

export default ({
  pageContext: { shop, root },
}: PageProps<{}, { shop: GatsbyShop; root: string }>) => {
  return (
    <Layout shop={shop} root={root}>
      <SEO title={`Conditions générales de vente | ${shop.name}`} />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{
            top: "4.625rem",
          }}
        >
          <Grid xs={12} sm={10}>
            <Button
              color="primary"
              href={`${root}/`}
              startIcon={<NavigateBefore />}
              sx={{ mb: 1, textTransform: "capitalize" }}
            >
              Retourner à la boutique
            </Button>
            <Typography sx={{ mt: 3, mb: 2 }} variant="h1">
              Conditions générales de vente
            </Typography>
            <Typography sx={{ my: 1 }}>
              <Typography sx={{ fontStyle: "italic", mt: 3, mb: 1 }}>
                L'abus d’alcool est dangereux pour la santé, à consommer avec
                modération. La vente d’alcool aux mineurs de moins de 18 ans est
                interdite.
              </Typography>
              <Typography sx={{ fontStyle: "italic", mb: 2 }}>
                Dernière mise à jour le 31 mai 2021.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Préambule
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le présent site Internet <strong>https://{shop.domain}</strong>{" "}
                est édité par la société <strong>Naoned IT</strong> - 2 rue
                Robert le Ricolais – 44300 Nantes - RCS Nantes 808 278 329 - SAS
                au Capital de 30.000,00 € - N° TVA Intra FR 93 808 278 329
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 1 : Domaine d’application
              </Typography>
              <Typography sx={{ my: 1 }}>
                Ces Conditions Générales de Vente s'appliquent à toutes les
                commandes passées par le biais du site Internet{" "}
                <strong>https://{shop.domain}</strong> et ce quelles que soient
                par ailleurs les clauses pouvant figurer sur les documents de
                l'acheteur.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le <strong>Client</strong> reconnaît avoir pris connaissance des
                présentes Conditions Générales de Vente et en accepter les
                termes. Lors d’un cas non traité par les présentes Conditions
                Générales de Vente, ce sont les règles de vente à distance du
                lieu où siège la société qui s’appliqueraient.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 2 : Commandes
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> s'engage à accepter les
                commandes effectuées dans les termes des présentes Conditions
                Générales de Vente et dans la limite des stocks disponibles.
              </Typography>
              <Typography sx={{ my: 1 }}>
                En cas de rupture de stock,{" "}
                <strong>{shop.legal?.company}</strong> s’engage à communiquer
                les délais d’approvisionnement nécessaires pour obtenir le
                produit souhaité ou à proposer un produit de remplacement.{" "}
                <strong>{shop.legal?.company}</strong> honorera chaque commande
                en effectuant une ou plusieurs livraisons des produits
                commandés.
              </Typography>
              <Typography sx={{ my: 1 }}>
                En validant sa commande, le <strong>Client</strong> déclare
                avoir pris connaissance et accepter les présentes Conditions
                Générales de Vente. La préparation et l’expédition d’une
                commande ne pourra être mise en œuvre qu’après réception du
                paiement. Une facture sera transmise par email dès l’expédition
                de la commande.
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> se réserve le droit
                d'annuler toutes les commandes non conformes ou douteuses, ainsi
                que celles issues d'un <strong>Client</strong> avec lequel il
                existerait un quelconque litige.{" "}
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 3 : Prix
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les prix indiqués TTC sont exprimés toutes taxes comprises (TVA
                applicable, accises et toutes autres taxes applicables au jour
                de la conclusion de la commande, tout changement pouvant être
                répercuté sur le prix des produits).
              </Typography>
              <Typography sx={{ my: 1 }}>
                En cas de livraison hors de la France Métropolitaine, les prix
                affichés sont TVAC (TVA comprise) et sont complétés des droits
                d’accises et autres taxes du pays du <strong>Client</strong> (et
                toutes autres taxes applicables au jour de la conclusion de la
                commande, tout changement pouvant être répercuté sur le prix des
                produits), ainsi que de frais de gestion administrative des
                formalités douanières.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les prix des produits peuvent évoluer à tout moment en fonction
                de la politique tarifaire de{" "}
                <strong>{shop.legal?.company}</strong>. Les produits commandés
                sont facturés au prix en vigueur au moment de l’enregistrement
                de la commande.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les offres de produits et les prix ne sont valables que tant
                qu’ils sont visibles sur le site et dans la limite des stocks
                disponibles.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les prix affichés sont nets et hors frais de port. Les frais de
                port peuvent varier en fonction du nombre de produits commandés
                et du pays d’expédition.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 4 : Validité des offres
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les produits et les offres promotionnelles sont proposés dans la
                limite des stocks disponibles.
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> ne pourra être tenu
                responsable d'une éventuelle rupture de stock qui entraînerait
                un retard dans la livraison de la commande.
              </Typography>
              <Typography sx={{ my: 1 }}>
                En cas d’indisponibilité d’un ou plusieurs produits après la
                validation de la commande,{" "}
                <strong>{shop.legal?.company}</strong> s’engage à informer le{" "}
                <strong>Client</strong> par e-mail dans les plus brefs délais.
                En accord avec le <strong>Client</strong>,{" "}
                <strong>{shop.legal?.company}</strong> pourra modifier la
                commande ou procédera au remboursement du ou des produits non
                disponibles par le crédit de la carte de paiement ayant servi
                lors de la commande.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 5 : Livraison
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> livre en France
                Métropolitaine. Les frais de port sont automatiquement calculés
                sur notre site suivant la destination de la commande et la
                quantité de produits commandés.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les livraisons pour toute autre destination devront faire
                l’objet d’un devis spécifique.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les vins proviennent de notre domaine.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le délai de livraison des commandes est de 7 jours ouvrés pour
                la France Métropolitaine après la réception du règlement. Dans
                les jours non ouvrés sont inclus les samedis, dimanches et jours
                fériés, ainsi que les jours où le transport routier est
                interdit. Ces délais sont indiqués dans la confirmation de la
                commande reçue par e-mail par le <strong>Client</strong>. La
                commande est livrée à l'adresse de livraison choisie par le{" "}
                <strong>Client</strong>.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le <strong>Client</strong> est tenu de vérifier attentivement
                ses colis à leur livraison afin d’émettre immédiatement
                d’éventuelles réserves auprès du transporteur. Aucune
                réclamation ne pourra être jugée recevable si le{" "}
                <strong>Client</strong> a accepté sans réserve ses colis lors de
                leur livraison.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Toute erreur ou modification faite par le{" "}
                <strong>Client</strong> dans un ordre de livraison en cours et
                ce quelle qu’elle soit, entraîne une prorogation du délai de
                livraison prévu. Les frais supplémentaires seront à la charge du{" "}
                <strong>Client</strong>.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 6 : Rétractation
              </Typography>
              <Typography sx={{ my: 1 }}>
                S'agissant d'une commande de produits :
              </Typography>
              <Typography sx={{ my: 1 }}>
                En application des dispositions de l'article L.221-18 du Code de
                la Consommation, le <strong>Client</strong> dispose d'un délai
                de quatorze jours francs à compter de la livraison de sa
                commande pour faire retour de l'article livré pour échange ou
                remboursement, au choix du Client, sans pénalités, à l'exception
                des frais de retour.
              </Typography>
              <Typography sx={{ my: 1 }}>
                En application de l'article L. 221-28 du Code de la
                consommation, le <strong>Client</strong> est informé du fait que
                le droit de rétractation ne peut être exercé pour certains
                contrats.
              </Typography>
              <Typography sx={{ my: 1 }}>
                La notification de la volonté de rétractation pourra se faire
                par mail ou par téléphone (voir la page Mentions Légales pour
                les coordonnées).
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> s’engage à rembourser le{" "}
                <strong>Client</strong> dans un délai maximum de quatorze jours
                après notification de la rétractation et sous condition du
                retour des produits.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les frais de retour du produit restent à la charge du{" "}
                <strong>Client</strong>.{" "}
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les retours devront être expédiés à l'adresse :{" "}
                <strong>{shop.legal?.company}</strong> -{" "}
                <strong>{shop.legal?.registeredOffice}</strong>
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 7 : Paiement
              </Typography>
              <Typography sx={{ my: 1 }}>
                Toute commande implique un paiement défini selon les modalités
                et les moyens précisés dans le processus de commande.
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> a donné un mandat
                d’encaissement total ou partiel à la société{" "}
                <strong>Naoned IT</strong>.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les règlements par carte bancaire sont effectués via un service
                de paiement en ligne sécurisé.
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> et{" "}
                <strong>Naoned IT</strong> n'ont jamais accès aux données
                bancaires des Clients.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Un ordre de paiement effectué par carte bancaire ne peut être
                annulé. Dès lors, le paiement de la commande par l’acheteur est
                irrévocable, sans préjudice pour le <strong>Client</strong>{" "}
                d’exercer son droit de rétractation ou d’annulation ultérieure
                de la commande.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 8 : Réserve de propriété
              </Typography>
              <Typography sx={{ my: 1 }}>
                Conformément à la loi du 1er mai 1980, les produits livrés au
                <strong>Client</strong> sont vendus sous réserve de propriété.
                Le transfert de propriété est subordonné au paiement total du
                prix des produits par le <strong>Client</strong>, à l'échéance
                convenue entre les parties. En cas de non-paiement du prix total
                des marchandises à l'échéance convenue,{" "}
                <strong>{shop.legal?.company}</strong> pourra revendiquer la
                propriété des produits aux frais et risques du{" "}
                <strong>Client</strong>.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 9 : Données personnelles
              </Typography>
              <Typography sx={{ my: 1 }}>
                Toutes les données fournies lors de vos visites des Clients sur{" "}
                <strong>https://{shop.domain}</strong> sont strictement
                confidentielles. Aucunes des données ou coordonnées personnelles
                des Clients ne seront vendues ou louées à qui que ce soit sans
                leur accord préalable. Les données personnelles des clients
                seront utilisées par <strong>{shop.legal?.company}</strong> &{" "}
                <strong>Naoned IT</strong> en vue du traitement des commandes.
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> pourra être amené à
                collecter, stocker, utiliser les données suivantes : nom,
                adresse email, coordonnées physiques, date de naissance et
                parfois des informations de livraison, facturation ou autres
                informations liées à la commande.
              </Typography>
              <Typography sx={{ my: 1 }}>
                En application de la loi Informatique et Libertés du 6 janvier
                1978, le <strong>Client</strong> dispose d'un droit d'accès, de
                modification, de rectification et de suppression des données qui
                le concernent. Le <strong>Client</strong> peut exercer ce droit
                en envoyant un courrier à <strong>{shop.legal?.company}</strong>
                .
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 10 : Utilisation de cookies
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> a recours à l’utilisation
                de cookies - via le site internet{" "}
                <strong>https://{shop.domain}</strong> - afin d’enregistrer les
                préférences des visiteurs; d’assurer le bon fonctionnement du
                site et de recueillir des données analytiques. Les cookies sont
                de petits fichiers stockés sur l'ordinateur du Client par son
                navigateur lorsqu'il visite un site internet.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le <strong>Client</strong> a la possibilité de désactiver
                l’utilisation de ces cookies en sélectionnant les paramètres
                appropriés sur son ordinateur. Cependant, cette désactivation
                aura pour conséquence d'empêcher l'accès à certaines
                fonctionnalités du site.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 11 : Garanties
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> est tenue de la garantie
                légale des vices cachés, au sens de l'article 1641 et suivants
                du Code Civil : "Le vendeur est tenu de la garantie à raison des
                défauts cachés de la chose qui la rendent impropre à l'usage
                duquel on la destine, ou qui diminuent tellement cet usage, que
                l'acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un
                moindre prix, s'il les avait connus". En conséquence, le{" "}
                <strong>Client</strong> a jusqu'à 2 mois après la date de
                livraison pour faire parvenir sa plainte à{" "}
                <strong>{shop.legal?.company}</strong>. Au-delà de ce délai, la
                société <strong>{shop.legal?.company}</strong> ne pourra plus
                garantir les produits et le <strong>Client</strong> devra
                prouver que leur altération éventuelle est antérieure à leur
                réception.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Les produits bénéficient de la garantie légale de conformité
                prévue par les articles L217-4 et suivants du Code de la
                Consommation.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Il est rappelé que le <strong>Client</strong> :
                <ul>
                  <li>
                    Bénéficie d'un délai de deux ans à compter de la délivrance
                    du bien pour agir;
                  </li>
                  <li>
                    Peut choisir entre la réparation ou le remplacement du bien,
                    sous réserve des conditions de coût prévues par l'article L.
                    217-9 du Code de la Consommation;
                  </li>
                  <li>
                    Est dispensé de rapporter la preuve de l'existence du défaut
                    de conformité du bien durant les vingt-quatre mois suivant
                    la délivrance du bien.
                  </li>
                </ul>
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le <strong>Client</strong> qui souhaite invoquer la garantie
                légale de conformité doit en informer{" "}
                <strong>{shop.legal?.company}</strong>. Si les motifs du retour
                sont fondés, les Produits concernés doivent être retournés à
                l’adresse indiquée dans l’Article 6 : Rétractation, dans les
                mêmes conditions que celles présidant à l’exercice du droit de
                rétractation. Les frais de livraison ainsi que les frais de
                retour seront remboursés.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Le remboursement du ou des Produit(s) retournés s’effectuera par
                crédit du compte bancaire lié à la carte bancaire utilisée pour
                le paiement de la commande.
              </Typography>
              <Typography sx={{ my: 1 }}>
                La date de remboursement peut être différée jusqu’à la date de
                réception effective du ou des Produit(s) à l’adresse ci-dessus.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 12 : Responsabilité
              </Typography>
              <Typography sx={{ my: 1 }}>
                <strong>{shop.legal?.company}</strong> se réserve le droit
                d’effectuer des modifications sur son site internet, ses
                procédures et les présentes Conditions Générales de Vente. Le{" "}
                <strong>Client</strong> est soumis aux Conditions Générales de
                Vente en vigueur au moment du passage de sa commande.{" "}
                <strong>{shop.legal?.company}</strong> ne pourra être tenu pour
                responsable, ou considéré comme ayant failli aux présentes
                conditions, pour tout retard ou inexécution, lorsque la cause du
                retard ou de l’inexécution est liée à un cas de force majeure
                telle qu’elle est définie par la jurisprudence des cours et
                tribunaux français.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 13 : Droit applicable
              </Typography>
              <Typography sx={{ my: 1 }}>
                La langue du présent contrat est la langue française. Toutes les
                clauses des présentes Conditions Générales de Vente, toutes les
                transactions et opérations d'achat effectuées depuis le site{" "}
                <strong>https://{shop.domain}</strong> ainsi que par courrier et
                par téléphone sont régies par le Droit et la loi Française. Tout
                litige qui n'aura pas trouvé d'accord à l'amiable sera du
                ressort des Tribunaux du siège social de la Société{" "}
                <strong>{shop.legal?.company}</strong> ou du tribunal du
                département choisi par le <strong>Client</strong>.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Article 14 : Protection des mineurs
              </Typography>
              <Typography sx={{ my: 1 }}>
                Conformément à l’article L. 3342-1 du code de la santé publique,
                la vente d’alcool à des mineurs de moins de dix-huit (18) ans
                est interdite. Le <strong>Client</strong> s’engage à avoir 18
                ans révolus en passant sa commande sur le site{" "}
                <strong>https://{shop.domain}</strong>.
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Principaux textes applicables
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
                Article L217-4 du Code de la Consommation
              </Typography>
              <Typography sx={{ my: 1 }}>
                "Le vendeur livre un bien conforme au contrat et répond des
                défauts de conformité existant lors de la délivrance.
              </Typography>
              <Typography sx={{ my: 1 }}>
                Il répond également des défauts de conformité résultant de
                l'emballage, des instructions de montage ou de l'installation
                lorsque celle-ci a été mise à sa charge par le contrat ou a été
                réalisée sous sa responsabilité".
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
                Article L217-5 du Code de la Consommation
              </Typography>
              <Typography sx={{ my: 1 }}>
                "Le bien est conforme au contrat :
              </Typography>
              <Typography sx={{ my: 1 }}>
                1° S'il est propre à l'usage habituellement attendu d'un bien
                semblable et, le cas échéant :
                <ul>
                  <li>
                    s'il correspond à la description donnée par le vendeur et
                    possède les qualités que celui-ci a présentées à l'acheteur
                    sous forme d'échantillon ou de modèle;
                  </li>
                  <li>
                    s'il présente les qualités qu'un acheteur peut légitimement
                    attendre eu égard aux déclarations publiques faites par le
                    vendeur, par le producteur ou par son représentant,
                    notamment dans la publicité ou l'étiquetage ;
                  </li>
                </ul>
              </Typography>
              <Typography sx={{ my: 1 }}>
                2° Ou s'il présente les caractéristiques définies d'un commun
                accord par les parties ou est propre à tout usage spécial
                recherché par l'acheteur, porté à la connaissance du vendeur et
                que ce dernier a accepté".
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
                Article L217-12 du Code de la Consommation
              </Typography>
              <Typography sx={{ my: 1 }}>
                "L'action résultant du défaut de conformité se prescrit par deux
                ans à compter de la délivrance du bien".
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
                Article L221-28 du Code de la Consommation
              </Typography>
              <Typography sx={{ my: 1 }}>
                "Le droit de rétractation ne peut être exercé pour les contrats
                (…) :
              </Typography>
              <Typography sx={{ my: 1 }}>
                7° De fourniture de boissons alcoolisées dont la livraison est
                différée au-delà de trente jours et dont la valeur convenue à la
                conclusion du contrat dépend de fluctuations sur le marché
                échappant au contrôle du professionnel (…)".
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
                Article 1641 du Code civil
              </Typography>
              <Typography sx={{ my: 1 }}>
                "Le vendeur est tenu de la garantie à raison des défauts cachés
                de la chose vendue qui la rendent impropre à l'usage auquel on
                la destine, ou qui diminuent tellement cet usage que l'acheteur
                ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre
                prix, s'il les avait connus".
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
                Article L3342-1 Code de la santé publique
              </Typography>
              <Typography sx={{ my: 1 }}>
                "La vente des boissons alcooliques à des mineurs est interdite.
                L'offre de ces boissons à titre gratuit à des mineurs est
                également interdite dans les débits de boissons et tous
                commerces ou lieux publics. La personne qui délivre la boisson
                exige du client qu'il établisse la preuve de sa majorité (…)".
              </Typography>
              <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
                Médiation de la consommation
              </Typography>
              <Typography sx={{ my: 1 }}>
                Selon l'article L.612-1 du Code de la consommation, il est
                rappelé que « tout consommateur a le droit de recourir
                gratuitement à un médiateur de la consommation en vue de la
                résolution amiable du litige qui l’oppose à un professionnel. A
                cet effet, le professionnel garantit au consommateur le recours
                effectif à un dispositif de médiation de la consommation ». A ce
                titre <strong>{shop.legal?.company}</strong> propose à ses
                Clients Consommateurs, dans le cadre de litiges qui n'auraient
                pas trouvé résolution de manière amiable, la médiation d'un
                médiateur de la consommation, dont les coordonnées sont les
                suivantes :
                <ul>
                  <li>
                    Atlantique Médiation -
                    http://consommation.atlantique-mediation.org/
                  </li>
                  <li>consommation@atlantique-mediation.org</li>
                </ul>
              </Typography>
              <Typography sx={{ my: 1 }}>
                Il est rappelé que la médiation n'est pas obligatoire mais
                uniquement proposée afin de résoudre les litiges en évitant un
                recours à la justice.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
